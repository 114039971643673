/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/bootstrap@3.3.5/dist/js/bootstrap.min.js
 * - /npm/slick-carousel@1.5.9/slick/slick.min.js
 * - /npm/magnific-popup@1.0.0/dist/jquery.magnific-popup.min.js
 * - /npm/gridalicious@3.0.1/jquery.grid-a-licious.min.js
 * - /npm/jquery-circle-progress@1.1.3/dist/circle-progress.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
